import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Accordion, AccordionGroup } from "@/components/accordion";
import { ButtonLink } from "@/components/button";
import { Link } from "@/components/link";
import { PortableText } from "@/components/portable/portable-text";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { getIcon, resolveSanityUrl } from "@/sanity/lib/utils";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";

export type ThemeProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    theme: SanityTheme;
    hideButton?: boolean;
  };

export const variants = cva({
  base: "border-t-2 py-6 transition-colors has-[a[data-title][data-hovered],a[data-title][data-focused]]:bg-brand-gray-400 md:px-6",
});

export function Theme({ theme, hideButton, className, ...props }: ThemeProps) {
  const { title, slug, icon, color, overview, topics, heading } = theme;

  const Icon = getIcon(icon)?.icon;

  const Title = () => {
    return (
      <h2 className="flex items-center gap-x-2 text-brand-gray-900">
        {isDefinedAndNotEmpty(Icon) && (
          <Icon strokeWidth={1.5} color={color} className="mt-0.5 flex-shrink-0" />
        )}
        {heading || title}
      </h2>
    );
  };

  return (
    <div
      className={cx(variants(), className)}
      style={{
        borderColor: color,
      }}
      {...props}
    >
      <div className="flex h-full flex-col gap-y-12">
        {hideButton ? (
          <Link
            href={hideButton ? resolveSanityUrl({ type: "theme", slug: slug?.current }) : undefined}
            className="flex w-full items-center justify-between"
            aria-label={`Go to ${title} theme`}
            data-title
          >
            <Title />
            <ArrowRight size={24} strokeWidth={1.5} className="mt-0.5 flex-shrink-0" />
          </Link>
        ) : (
          <Title />
        )}
        <div className="mb-0 mt-auto space-y-4">
          <AccordionGroup>
            {isDefinedAndNotEmpty(overview) && (
              <Accordion header={<p className="font-h5">Overview</p>} defaultOpen>
                <PortableText value={overview} className="text-sm" />
              </Accordion>
            )}
            {isDefinedAndNotEmpty(topics) && (
              <Accordion header={<p className="font-h5">Topics</p>}>
                <ul className="pl-4 text-sm">
                  {topics.map((topic, index) => {
                    return (
                      <li key={index} className="list-disc">
                        {topic.title}
                      </li>
                    );
                  })}
                </ul>
              </Accordion>
            )}
          </AccordionGroup>
          {!hideButton && (
            <ButtonLink
              href={resolveSanityUrl({ type: "theme", slug: slug?.current })}
              isExternal={true}
              className="max-w-full"
            >
              Go to theme
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}
