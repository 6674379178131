import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { variants as sectionVariants } from "@/components/section";
import { Theme } from "@/components/theme";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";

export type ThemesProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    themes: SanityTheme[];
    hideButtons?: boolean;
  };

export const variants = cva({
  base: "grid grid-cols-12 gap-y-6 md:gap-x-8",
});

export function Themes({ themes, hideButtons, className, ...props }: ThemesProps) {
  return (
    <div className={cx(sectionVariants(), variants(), className)} {...props}>
      {isDefinedAndNotEmpty(themes) &&
        themes.map((theme, index) => {
          return (
            <Theme
              key={index}
              theme={theme}
              className="col-span-12 md:col-span-6 xl:col-span-4"
              hideButton={hideButtons}
            />
          );
        })}
    </div>
  );
}
