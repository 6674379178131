import { ButtonLink } from "@/components/button";
import { EventRoadmap } from "@/components/event-roadmap";
import { HeadingSection } from "@/components/heading-section";
import { Section } from "@/components/section";
import { Themes } from "@/components/themes";
import { maximumEventsOnHomepage } from "@/lib/settings";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { defaultTitle } from "@/sanity/lib/data";
import { resolveSanityUrl } from "@/sanity/lib/utils";
import { type SanityHome } from "@/sanity/schemas/documents/singletons/home";

export type HomeLayoutProps = {
  data: SanityHome | null;
};

export function HomeLayout({ data }: HomeLayoutProps) {
  const {
    title = defaultTitle,
    heading,
    description,
    roadmapHeading,
    roadmapDescription,
    themes,
    topics,
    events,
    calendarEvents,
    years,
  } = data || {};

  return (
    <Section>
      <HeadingSection>
        <h1>{heading || title}</h1>
        {isDefinedAndNotEmpty(description) && <p className="text-brand-gray-600">{description}</p>}
      </HeadingSection>
      {isDefinedAndNotEmpty(themes) && <Themes themes={themes} hideButtons />}
      <HeadingSection>
        {isDefinedAndNotEmpty(roadmapHeading) && <h2 className="font-h1">{roadmapHeading}</h2>}
        {isDefinedAndNotEmpty(roadmapDescription) && (
          <p className="text-brand-gray-600">{roadmapDescription}</p>
        )}
      </HeadingSection>
      <EventRoadmap
        themes={themes}
        topics={topics}
        events={events}
        calendarEvents={calendarEvents}
        years={years}
        display="limited"
      />
      {isDefinedAndNotEmpty(events) && (
        <div>
          <ButtonLink
            href={resolveSanityUrl({ type: "event" })}
            isExternal={true}
            className="max-w-full"
          >
            {events.length - 1 >= maximumEventsOnHomepage ? "View events" : "View all other events"}
          </ButtonLink>
        </div>
      )}
    </Section>
  );
}
