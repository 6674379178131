"use client";

import { type QueryResponseInitial } from "@sanity/react-loader";

import { HomeLayout } from "@/components/documents/home/home.layout";
import { useHome } from "@/sanity/loader/use-query";
import { type SanityHome } from "@/sanity/schemas/documents/singletons/home";
import { type NextSearchParams } from "@/types";

export type HomeClientProps = {
  initial: QueryResponseInitial<SanityHome | null>;
  searchParams: NextSearchParams;
};

export function HomeClient({ initial, searchParams }: HomeClientProps) {
  const { data } = useHome(initial, searchParams);

  return <HomeLayout data={data} />;
}

export default HomeClient;
